<template>

  <article v-if="goals.length === 0" class="content-section">
    <div class="none-contents">
      <p class="text">현재 설정된 목표가 없어요 <br>KB인으로서의 당신만의 목표를 설정해주세요.</p>
    </div>
  </article>

  <template v-else>
    <article class="content-section section-goal">
      <header class="card-header">
        <div class="header-title" style="width: 200px;">
          <h3 class="title">내 학습목표</h3>
          <p class="subtitle">{{ goalTalent.text }}</p>
        </div>
        <div class="header-side"><span class="subtext">{{ restDay > -1 ? `D-${restDay}` : '-' }}</span></div>
      </header>
      <div class="level-contents">
        <div class="certificate-container">
          <template v-if="hopeLicenses.length > 0">
            <h4 class="certificate-title">취득희망자격증</h4>
            <ul class="certificate-list" style="display: initial;">
              <li v-for="(hopeLicense, index) in hopeLicenses" class="certificate" :class="[isLicenseGet(hopeLicense) && 'is-active']" :key="index" style="margin-bottom: 8px;">
                <span class="text text-skip" style="width: 50px;" :title="getLicenseNm(hopeLicense)">{{getLicenseNm(hopeLicense)}}</span>
              </li>
            </ul>
          </template>
        </div>

        <div class="running-level-container" :data-level="talentLevel">
          <div class="level-bar"><i class="icon-running"></i></div>
          <ul class="level-steps">
            <li class="level-step step-active">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title">초급
<!--                <br>(Beginner)-->
              </span></div>
            </li>
            <li class="level-step">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title">중급
<!--                <br>(Intermediate)-->
              </span></div>
            </li>
            <li class="level-step">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title">고급
<!--                <br>(Advanced)-->
              </span></div>
            </li>
            <li class="level-step step-goal">
              <div class="step-dot"></div>
              <div class="step-content">
                <span class="title">전문가
<!--                  <br>(Expert)-->
                </span>
                <strong class="title">목표</strong>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </article>

    <article class="content-section content-section-card section-my">
      <header class="card-header">
        <div class="header-title">
          <h3 class="title">{{ session.lrnerNm }}</h3>
        </div>
        <div class="header-side"><span class="text text-gold">{{ session.jbgdCd }}</span></div>
      </header>
      <div class="my-contents">
        <div class="my-meta">
          <div class="meta"><h4 class="title">현재 학습분야</h4><span class="text">{{ getCrseWorkNm(nowGoal.crseWorkDtlSn, works) }}</span></div>
          <div class="meta"><h4 class="title">우수 학습분야</h4><span class="text">{{ getCrseWorkNm(bestAbility.crseWorkDtlSn, works) }}</span></div>
          <div class="meta"><h4 class="title">희망 학습분야</h4><span class="text">{{ getCrseWorkNm(hopeGoal.crseWorkDtlSn, works) }}</span></div>
        </div>
        <div class="my-feedback">
          <div class="feed-list">
            <!-- feed -->
            <div v-if="hopeGoal.crseWorkDtlSn > 0" class="feed">
              <div class="feed-avatar">
                <ProfileImg
                    :target="session"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text"><a href="javascript:" class="feed-link" @click="goCoaching">{{hopeGoal.goalsCn ? hopeGoal.goalsCn : '-'}}</a></p>
                <div class="feed-meta"><strong class="sender">{{ session.lrnerNm }}</strong><span class="date">{{ timestampToDateFormat(hopeGoal.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
              </div>
            </div>
            <!-- feed -->
            <div v-if="coachings.length > 0" class="feed coaching">
              <div class="feed-avatar">
                <ProfileImg
                    :target="{lrnerId: coachings[0].mdfrId}"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text"><a href="javascript:" class="feed-link" @click="goCoaching">{{ coachings[0].goalsCn }}</a></p>
                <div class="feed-meta"><strong class="sender">{{ coachings[0].mdfrNm }} </strong><span class="date">{{ timestampToDateFormat(coachings[0].mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </template>
</template>

<script>

import ProfileImg from '@/components/common/ProfileImg';
import {talentGoalSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "MobileTalentGoal",
  components: {
    ProfileImg,
  },
  props: {
    works: Array,
    goals: Array,
    abilities: Array,
    aces: Array,
    licenseCodes: Array
  },
  emits: ['completed'],
  setup: talentGoalSetup
}
</script>
