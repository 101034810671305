<template>
  <LxpMobileHeader :use-route-name="true">
    <template v-slot:left>
      <div class="util util-more">
        <a href="javascript:" class="util-actions util-actions-search" @click="toggles.download = !toggles.download">
          <i class="icon-more"></i>
        </a>
        <div class="dropdown" :class="{'is-active': toggles.download}" data-offset="header-left">
          <div class="dropdown-target" style="width: 296px;">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item">
                  <file-down-link :file-key="guideFileKey" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <button class="kb-btn-setting" @click="goGoal"><i class="icon-goal"></i><span class="text">목표설정</span></button>
    </template>
  </LxpMobileHeader>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-talentboard-learner">
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section : 목표설정 -->
      <MobileTalentGoal
          :works="works"
          :goals="goals"
          :abilities="abilities"
          :aces="aces"
          :license-codes="licenseCodes"
      />

      <MobileTalentJourney
          :learns="learns"
          :license-codes="licenseCodes"
          :goals="goals"
      />

      <MobileTalentAbility
          v-if="allReadies"
          :works="works"
          :abilities="abilities"
          :aces="aces"
          :all-abilities="allAbilities"
          :license-codes="licenseCodes"
      />

      <MobileTalentSSL
          v-if="allReadies"
      />

    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {talentMainSetup} from '@/assets/js/modules/mypage/talent/talent-setup';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileTalentGoal from '@/components/mypage/talent/mobile/MobileTalentGoal';
import MobileTalentJourney from '@/components/mypage/talent/mobile/MobileTalentJourney';
import MobileTalentAbility from '@/components/mypage/talent/mobile/MobileTalentAbility';
import MobileTalentSSL from '@/components/mypage/talent/mobile/MobileTalentSSL';
import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: 'MobileMyTalentBoard',
  components: {MobileTalentSSL, MobileTalentAbility, MobileTalentJourney, MobileTalentGoal, LxpMobileHeader, FileDownLink,},
  setup: talentMainSetup
};
</script>
