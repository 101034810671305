<template>
  <article class="content-section content-section-card">
    <header class="section-header">
      <h3 class="title">
        나의 역량개발 학습지수
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>

    </header>
    <div class="tool-tip" :class="{'is-active': tooltip}" style="top:-40px">
      <div class="tool-tip-desc">
        현재시점의 학습분야별 역량개발 수준 중 상위 4개 분야를 시각화하여 초급/중급/고급/전문가 수준으로 표시합니다.<br>
        다음 세가지 항목을 점수화하여 나타냅니다.<br>
        ❶KB ACE Academy  : 학습분야별 수료한 Step
        ❷직무선택과정 : 과거 10년 동안 이수한 직무선택과정(기간별 가중치 적용)
        ❸등급자격증 : 보유한 등급자격증

      </div>
    </div>
    <!-- chart-swiper-container -->
    <div class="chart-swiper-container">
      <div class="chart-swiper js-chart-swiper">
        <div class="swiper-wrapper">
          <!-- swiper-slide -->
          <swiper
              :slides-per-view="1"
              :loop="false"
          >
            <swiper-slide v-for="(ability, index) in getDescOrderItems(abilityScores)" :key="index">
              <div class="chart-radialbar">
                <div class="chart-labels">
                  <div class="label">{{ ability.name }}
                    <div v-if="ability.isBest" class="badge"><span class="badge-text">우수</span></div>
                  </div>
                  <span class="level text-center"
                        style="font-size: 16px"
                        v-html="getLevelText(ability.level)"></span>
                </div>
                <ToastGaugeChart
                    style="left: -62px; top: -48px;"
                    :name="ability.name"
                    :data="ability.score"
                    :min="0"
                    :max="10"
                    :total-score="ability.score"
                    :color="ability.isBest ? '#FFBC00' : '#84888b'"
                    :width="300"
                    :height="300"
                    :use-plot="true"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
<!--      <button class="swiper-kb-nav-prev js-chart-prev"><i class="icon-swiper-prev"></i></button>-->
<!--      <button class="swiper-kb-nav-next js-chart-next"><i class="icon-swiper-next"></i></button>-->
    </div>
  </article>

  <article class="content-section content-section-card">
    <header class="section-header">
      <h3 class="title">역량개발 학습지수 분포차트</h3>
    </header>
    <div class="chart-legends chart-legends-side">
      <div class="legend"><span class="marker marker-gray64"></span><span class="text">나</span></div>
      <div class="legend"><span class="marker marker-gray91"></span><span class="text">동일직급 평균</span></div>
    </div>
    <div class="content-scrollwrap" style="margin: 0 0;">
      <div class="chart-column">
        <div class="chart-bar-series">
          <div v-for="(ability, index) in abilityScores" class="chart-series" :key="index">
            <div class="series-path path-gray64" :style="{height:`${getHeight(ability.totalScore)}px`}"></div>
            <div class="series-path path-gray91" :style="{height:`${getHeight(ability.allTotalScore)}px`}"></div>
          </div>
        </div>
        <div class="chart-yaxis">
          <div class="grid"><span class="grid-text">40</span></div>
          <div class="grid"><span class="grid-text">30</span></div>
          <div class="grid"><span class="grid-text">20</span></div>
          <div class="grid"><span class="grid-text">10</span></div>
          <div class="grid"><span class="grid-text">0</span></div>
        </div>
        <div class="chart-xaxis">
          <span v-for="(ability, index) in abilityScores"  class="chart-xaxis-text" :key="index">{{ ability.name }}</span>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import ToastGaugeChart from '@/components/chart/ToastGaugeChart';
import {talentAbilitySetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "MobileTalentAbility",
  components: {
    ToastGaugeChart,
    Swiper, SwiperSlide
  },
  props: {
    works: Array,
    abilities: Array,
    aces: Array,
    allAbilities: Array,
    licenseCodes: Array
  },
  setup: talentAbilitySetup
}
</script>
