<template>
  <article class="content-section content-section-card">
    <header class="section-header">
      <h3 class="title">
        자기주도학습 지수
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>
<!--      <div class="header-side">-->
<!--        <SortButtonSelect3-->
<!--            v-if="yearOptions.length > 1"-->
<!--            v-model="filters.year"-->
<!--            v-model:toggle="toggles.year"-->
<!--            title="년도"-->
<!--            :options="yearOptions"-->
<!--            sequence-key="value"-->
<!--            name-key="display"-->
<!--            :is-num="true"-->
<!--            @selected="selectedYear"-->
<!--        />-->
<!--        <div v-else class="kb-form-layer">-->
<!--          <button class="kb-form-layer-btn"><strong class="text">{{ filters.year }}년</strong></button>-->
<!--        </div>-->
<!--      </div>-->

    </header>
    <div class="tool-tip" :class="{'is-active': tooltip}" style="top:-40px">
      <div class="tool-tip-desc">
        연간 자기주도 학습의 노력 수준을 점수화하여 표시합니다. <br>
        ❶직무&amp;직무외 HRD클라우드 마일리지와 ❷SSL프로그램 학습활동의 합계로 구성됩니다. 나의 수준과 동일직급평균, 전체평균 비교가 가능합니다.

      </div>
    </div>
    <div style="text-align: center;" :class="{'lxp-layout d-none' : isTemp2024Ready}">
      <h3>2024년 자기주도학습 지표 준비중입니다.</h3>
    </div>
    <div class="chart-group-container" :class="{'lxp-layout d-none' : !isTemp2024Ready}">
      <!-- chart-multiple -->
      <div class="chart-card chart-multiple">
        <div v-if="sslReadies.ssl && sslReadies.group && sslReadies.hrd" class="chart">
          <ToastNestedPieChart
              :categories="['전체평균', '동일직급평균','나의지수']"
              :series="grapeStatus"
              :options="sslOptions2"
          />
        </div>
        <div class="chart-content">
          <h4 class="chart-title">자기주도학습 현황</h4>
          <div class="chart-legends">
            <div class="legend"><span class="marker marker-yellow"></span><span class="text">나의지수</span></div>
            <div class="legend"><span class="marker marker-darkbrown"></span><span class="text">동일직급평균</span></div>
            <div class="legend"><span class="marker marker-silver"></span><span class="text">전체평균</span></div>
          </div>
        </div>
      </div>
      <!-- chart-multiple -->
      <div class="chart-card chart-radar" :class="{'lxp-layout d-none' : !isTemp2024Ready}">
        <div v-if="sslReadies.ssl  && sslReadies.group && sslReadies.hrd" class="chart">
          <ToastRadarChart
              :categories="['가계여신', '기업여신/외환', '자산관리/퇴직연금', 'ICT', '그룹러닝']"
              :series="[
                {
                  name: '참여직원 전체평균',
                  data: grape.total,
                },
                {
                  name: '나의학습현황',
                  data: grape.mine,
                },

              ]"
              :options="sslOptions"
          />
        </div>
        <div class="chart-content">
          <h4 class="chart-title">SSL 학습활동 (학습노트 작성수 기준)</h4>
          <div class="chart-legends">
            <div class="legend"><span class="marker marker-yellow"></span><span class="text">나의학습현황</span></div>
            <div class="legend"><span class="marker marker-gray91"></span><span class="text">참여직원 전체평균</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kb-table-group-container" :class="{'d-none' : !isTemp2024Ready}">
      <div class="content-scrollwrap">
        <!-- table:HRD 클라우드 마일리지 -->
        <div class="kb-table kb-table-bordered kb-table-01">
          <div class="kb-table-header">
            <h4 class="title">HRD 클라우드 마일리지<br>
              (1일 최대 2마일리지)</h4>
          </div>
          <table>
            <colgroup>
              <col>
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">구분</span></th>
              <th><span class="th-title">동영상</span></th>
              <th><span class="th-title">전산실습</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong class="td-text">연간누적</strong></td>
              <td><span class="td-text">{{ onMlg.sumMlgValue }}M</span></td>
              <td><span class="td-text">{{ offMlg.sumMlgValue }}M</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- table:SSL 프로그램 학습활동 -->
        <div class="kb-table kb-table-bordered kb-table-03">
          <div class="kb-table-header">
            <h4 class="title">SSL 프로그램 학습활동</h4>
          </div>
          <table>
            <colgroup>
              <col>
              <col style="width:90px;">
              <col style="width:120px;">
              <col >
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">가계여신</span></th>
              <th><span class="th-title">기업여신/외환</span></th>
              <th><span class="th-title">자산관리/퇴직연금</span></th>
              <th><span class="th-title">ICT</span></th>
              <th><span class="th-title">그룹러닝</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <template v-for="(value, key, index) in targetSSLCodes" :key="index">
                <td   v-if="key.includes('self') || key ==='group'">
                  <span class="td-text" v-html="getScore(value, key)"></span>
                </td>
              </template>

            </tr>
            </tbody>
          </table>
        </div>
        <!-- table:&nbsp; -->
        <div class="kb-table kb-table-bordered kb-table-03">
          <div class="kb-table-header">
            <h4 class="title">&nbsp;</h4>
          </div>
          <table>
            <colgroup>
              <col>
            </colgroup>
            <thead>
            <tr>

              <th><span class="th-title">그룹러닝 학습모임(회당 5점)</span></th>
<!--              <th><span class="th-title">지식e진단평가(60점 이상 1회 10점)</span></th>-->
              <th><span class="th-title">SSL 소계</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span class="td-text" v-html="(groups.length > 0 ? `${groups[0].learnCnt}회<br>(${groups[0].learnCnt * 5}점)` : '0회')"></span></td>
<!--              {{getGljisikScore()}}-->
<!--              <td><span class="td-text">1건(5점)</span></td>-->
<!--              <td><span class="td-text">{{ sgGroupMCnt }}회<br>({{ sgGroupMCnt*5 }}점)</span></td>-->
<!--              <td><span class="td-text">{{jisikECnt}}회<br/>({{jisikECnt > 0 ? jisikECnt*10 : 0}}점)</span></td>-->

<!--              <td><span class="td-text" v-html="(knowledges.length > 0 ? `${knowledges[0].learnCnt}회<br>(${knowledges[0].sumGetValue}점)` : '0회')"></span></td>-->
              <td><span class="td-text">{{ sslTotalScore }}점</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- table:러닝셀 라이브특강 -->
        <div class="kb-table kb-table-bordered kb-table-04">
          <div class="kb-table-header">
            <h4 class="title">합계</h4>
          </div>
          <table>
            <colgroup>
<!--              <col style="width:130px">-->
              <col style="width:80px">
            </colgroup>
            <thead>
            <tr>
<!--              <th><span class="th-title">러닝셀 라이브 특강</span></th>-->
              <th><span class="th-title">합계</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
<!--              <td><span class="td-text">-</span></td>-->
              <td><span class="td-text">{{ totalScore }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

import ToastNestedPieChart from '@/components/chart/ToastNestedPieChart';
import ToastRadarChart from '@/components/chart/ToastRadarChart';
// import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import {talentSSLSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "MobileTalentSSL",
  components: { ToastRadarChart, ToastNestedPieChart},
  setup: talentSSLSetup,
}
</script>
